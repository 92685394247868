import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { formatTimestampToSpanish } from '../utils/formatters';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DOMPurify from 'dompurify';


const News = ({ keycloak, news }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { trackPageView } = useMatomo();

    const onLoad = () => {
        document.title = news.find(n => n.slug === location.pathname.split("/").pop()) ? news.find(n => n.slug === location.pathname.split("/").pop()).title + " | RedFID" : "Página no encontrada | RedFID";
        trackPageView();
        window.scrollTo(0, 0);
        return <></>;
    }

    return (
        (keycloak.didInitialize && news) &&
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
            {onLoad()}
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: '940px',
                    margin: "30px 20px",
                    width: "100%"
                }}
            >
                <Grid container spacing={1} p={"0 20px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    {
                        (news.find(n => n.slug === location.pathname.split("/").pop()) && news.find(n => n.slug === location.pathname.split("/").pop()).external_url === "") ?
                            <>
                                <Grid item xs={12}>
                                    <Typography onClick={() => navigate("/")} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                                        <Typography variant="back">Volver al Home</Typography>
                                    </Typography>
                                    <Typography mb={"10px"} variant='title' fontSize={{ "xs": "1.2em", "md": "1.6em" }} textAlign={"justify"}>{news.find(n => n.slug === location.pathname.split("/").pop()).title}</Typography>
                                    <Typography mb={"20px"} variant='p' textAlign={"right"}>
                                        {news.find(n => n.slug === location.pathname.split("/").pop()).author}
                                        {" — "}
                                        {formatTimestampToSpanish(news.find(n => n.slug === location.pathname.split("/").pop()).published_date)}
                                    </Typography>
                                </Grid>
                                {news.find(n => n.slug === location.pathname.split("/").pop()).content.map((c, index) => {
                                    return (
                                        <Grid item xs={12} display={"flex"} justifyContent={"center"} key={index}>
                                            {(() => {
                                                switch (c.type) {
                                                    case "title":
                                                        return <Typography variant='title-small' mb={"20px"}>{c.block}</Typography>
                                                    case "subtitle":
                                                        return <Typography variant='main-subtitle' mb={"20px"}>{c.block}</Typography>
                                                    case "paragraph":
                                                        return <Typography variant='p' mb={"20px"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.block) }} />
                                                    case "image":
                                                        return <img src={process.env.REACT_APP_API_URL + "/get_news_image/" + news.find(n => n.slug === location.pathname.split("/").pop()).id + "/" + c.image} alt={news.find(n => n.slug === location.pathname.split("/").pop()).title} style={{ maxHeight: "400px", maxWidth: "100%", marginBottom: "20px" }} />
                                                    default:
                                                        return <></>
                                                }
                                            })()}
                                        </Grid>
                                    )
                                })}
                                {news.filter(n => n.slug === location.pathname.split("/").pop()).map((n, index) => {
                                    if (index === 0) {
                                        const hasPreviousNews = news.filter(n2 => n2.priority < n.priority && n2.external_url === "").length > 0;
                                        const hasNextNews = news.filter(n2 => n2.priority > n.priority && n2.external_url === "").length > 0;
                                        const previousNews = hasPreviousNews ? news
                                            .filter(n2 => (n2.priority < n.priority) && (n2.external_url === ""))
                                            .reduce((prev, curr) => (prev.priority > curr.priority ? prev : curr), {}) : null;
                                        const nextNews = hasNextNews ? news
                                            .filter(n2 => n2.priority > n.priority && (n2.external_url === ""))
                                            .reduce((prev, curr) => (prev.priority < curr.priority ? prev : curr), {}) : null;
                                        return (
                                            <>
                                                <Grid item xs={5.5} md={4} display={"flex"} justifyContent={"flex-start"} mb={"20px"}>
                                                    {previousNews && (
                                                        <Typography onClick={() => navigate("/events/" + previousNews.slug)} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                                                            <ArrowBackIcon sx={{ fontSize: '1em', marginRight: "10px" }} variant="back" />
                                                            <Typography variant="back">{previousNews.title}</Typography>
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                <Grid item xs={5.5} md={4} display={"flex"} justifyContent={"flex-end"} mb={"20px"}>
                                                    {nextNews && (
                                                        <Typography onClick={() => navigate("/events/" + nextNews.slug)} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                                                            <Typography variant="back">{nextNews.title}</Typography>
                                                            <ArrowForwardIcon sx={{ fontSize: '1em', marginLeft: "10px" }} variant="forward" />
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </>
                            :
                            <Grid item xs={12} flexDirection={"column"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography textAlign={"center"} variant='title' mb={"20px"}>Página no encontrada</Typography>
                                <Typography textAlign={"center"} onClick={() => { keycloak.didInitialize && navigate("/") }} variant='a'>Volver al Home</Typography>
                            </Grid>
                    }
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default News;
